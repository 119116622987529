import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[380px] pb-[20px] md:pb-[110px] md:pt-[500px] ">
          <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
      <div className=" md:w-[70%] w-[100%] bg-white rounded-2xl text-white md:-mt-20 -mt-10 px-6 relative md:py-14 py-2 flex flex-row flex-wrap md:flex-row justify-center items-center space-x-0 md:space-x-10 mx-auto">
        <div className="w-auto">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2FSello-Google.png?alt=media&token=18da2ce4-4aa7-4dde-a826-3665b076b811"
            alt="sello Google"
            className="w-[150px] md:w-[250px] md:h-[250px] h-[150px]"
          />
        </div>
        <div className="w-auto">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2F22.png?alt=media&token=c4d2ea59-276d-491d-9d81-518c94905a03"
            alt="sello experiencia"
            className="w-[150px] md:w-[250px] md:h-[250px] h-[150px]"
          />
        </div>
        <div className="w-auto">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Insignias%2FGuarante%20Satisfaction.png?alt=media&token=17a2f265-6293-4771-b9d1-3ac6699df89b"
            alt="sello experiencia"
            className="w-[180px] md:w-[300px] h-[150px] md:h-[250px]"
          />
        </div>
      </div>
    </>
  );
}

export default HeroSection;
